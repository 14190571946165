import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Text, IText} from './Text';

interface ITextLink extends IText {
  path: string;
  children: React.ReactNode;
}

export function TextLink(props: ITextLink) {
  const { path, color, variant = 'body1', children, ...otherTextProps } = props;

  return (
    <Link sx={{ '&:hover': { textDecoration: 'underline' } }} component={RouterLink} to={path}>
      <Text variant={variant} color={color} {...otherTextProps}>
        {children}
      </Text>
    </Link>
  );
}
