export enum IColors {
  WHITE = 'WHITE',
  BLACK = 'BLACK',
  primary = 'primary',
  lightOrange = 'lightOrange',
  chocolate = 'chocolate',
}

export const Colors: {[x in IColors]: string} = {
  WHITE: '#FFF',
  BLACK: '#000',
  primary: '#D2691E',
  lightOrange: '#FFEBCD',
  chocolate: '#D2691E',
};
