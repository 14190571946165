import React from 'react';
import { ERoute, IRoute } from './RouteTypes';
import { GetRoute } from './RouteConfig';

export const RouteMaster: {[x in ERoute]: IRoute} = {
  [ERoute.dashboard]: GetRoute(ERoute.dashboard),
  [ERoute.development]: GetRoute(ERoute.development),
  [ERoute.login]: GetRoute(ERoute.login),
  [ERoute.signup]: GetRoute(ERoute.signup),
  [ERoute.root]: GetRoute(ERoute.root),
};
