import React from 'react';
import { ERoute, IRoute } from './RouteTypes';
import { Splash } from '../Splash/Splash';
import { PageError } from './PageError';
import { SignIn } from '../SignIn/SignIn';
import { SignUp } from '../SignUp/SignUp';
import { Dashboard } from '../Dashboard/Dashboard';
import { Development } from '../Development/Development';

export const RouteConfig: IRoute[] = [
  {
    name: ERoute.root,
    path: '/',
    title: 'Strengths Identifier',
    component: <Splash />,
    errorElement: <PageError />,
  },
  {
    name: ERoute.login,
    path: '/login',
    title: 'Sign In - Strengths Identifier',
    component: <SignIn />,
    errorElement: <PageError />,
  },
  {
    name: ERoute.signup,
    path: '/signup',
    title: 'Sign Up - Strengths Identifier',
    component: <SignUp />,
    errorElement: <PageError />,
  },
  {
    name: ERoute.dashboard,
    path: '/dashboard',
    title: 'Dashboard - Strengths Identifier',
    component: <Dashboard />,
    errorElement: <PageError />,
  },
  {
    name: ERoute.development,
    path: '/development',
    title: 'Development - Strengths Identifier',
    component: <Development />,
    errorElement: <PageError />,
  },
];

export function GetRoute(routeName: ERoute): IRoute {
  return RouteConfig.find((r) => r.name === routeName) as IRoute;
}
