import { TypographyProps, Typography } from '@mui/material';
import { Colors, IColors } from './Colors';

export interface IText extends TypographyProps {
  color?: IColors;
  overrideColor?: string;
  fontWeight?: number;
}

export function Text(props: IText) {
  const { overrideColor, ...other } = props;
  return (
    <Typography {...other} sx={{ color: props.overrideColor ?? Colors[props.color ?? IColors.BLACK], fontWeight: props.fontWeight ?? 500, ...props.sx }} />
  );
}
