import React from 'react';
import Grid2 from '@mui/material/Grid2';
import { Text } from '../Shared/Text';

export function Dashboard() {
  return (
    <Grid2 container justifyContent="center">
      <Grid2>
        <Text variant="h2">
          Strengths Identifier Dashboard
        </Text>
      </Grid2>
    </Grid2>
  );
}
