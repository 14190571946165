import React from 'react';
import Grid2 from '@mui/material/Grid2';
import { Text } from '../Shared/Text';

export function PageError() {
  return (
    <Grid2 container justifyContent="center">
      <Grid2>
        <Text variant="h2">Something went wrong &#128533;</Text>
      </Grid2>
    </Grid2>
  );
};
