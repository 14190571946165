import * as React from 'react';
import Grid2 from '@mui/material/Grid2';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Drawer from '@mui/material/SwipeableDrawer';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Logo from './logo.png';
import { Text } from '../Shared/Text';
import { RouteMaster } from './RouteMaster';
import { TextLink } from '../Shared/TextLink';
import { Colors, IColors } from '../Shared/Colors';
import { ExitToApp } from '@mui/icons-material';
import { AuthenticationContext } from '../Contexts/Authentication';
import { brand } from '../SignIn/getThemeMode';
import Box from '@mui/material/Box';

const pages = [
  {
    name: 'Dashboard', path: RouteMaster.dashboard.path,
  },
  {
    name: 'Development', path: RouteMaster.development.path,
  },
];

const settings = ['Profile', 'Dashboard'];
export const NavigationBarHeight = 80;
export const NavigationDrawerWidth = 160;

export function NavigationBar() {
  const [openNavDrawer, setOpenNavDrawer] = React.useState<boolean>(false);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const { signOut } = React.useContext(AuthenticationContext);

  const toggleNavDrawer = () => {
    setOpenNavDrawer(!openNavDrawer);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static" sx={{ height: NavigationBarHeight }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Grid2 container alignItems="center" justifyContent="space-between" size={{ xs: 12 }}>
            <Grid2 container size={{ xs: 8 }}>
              <Grid2 container sx={{ display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={toggleNavDrawer}
                  color="inherit"
                  sx={{ height: NavigationBarHeight, '&:hover': { background: 'transparent' } }}
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  id="navigation-drawer"
                  ModalProps={{
                    keepMounted: true,
                  }}
                  onOpen={toggleNavDrawer}
                  anchor="left"
                  open={openNavDrawer}
                  onClose={toggleNavDrawer}
                  sx={{
                    display: { md: 'none' },
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                      boxSizing: 'border-box',
                      top: NavigationBarHeight,
                      position: 'absolute',
                      p: 2,
                    },
                    '& .MuiPaper-root': {
                      width: NavigationDrawerWidth,
                    },
                  }}
                >
                  {pages.map((page) => (
                    <Grid2 container spacing={6} key={page.name}>
                      <TextLink variant="h5" key={page.name} onClick={toggleNavDrawer} path={page.path} overrideColor={brand[700]} sx={{ mt: 4 }}>
                        {page.name}
                      </TextLink>
                    </Grid2>
                  ))}
                </Drawer>
              </Grid2>
              <Grid2 container alignItems="center">
                {/*<Text*/}
                {/*  variant="body1"*/}
                {/*  noWrap*/}
                {/*  component="a"*/}
                {/*  color={IColors.primary}*/}
                {/*  sx={{*/}
                {/*    mr: 2,*/}
                {/*    display: { xs: 'none', md: 'flex' },*/}
                {/*    fontFamily: 'monospace',*/}
                {/*    fontWeight: 700,*/}
                {/*    letterSpacing: '.3rem',*/}
                {/*    textDecoration: 'none',*/}
                {/*  }}*/}
                {/*>*/}
                {/*  Strengths Identifier*/}
                {/*</Text>*/}
                <Box sx={{ mr: 8 }}>
                  <img src={Logo} height={NavigationBarHeight} alt="strength identifier logo"/>
                </Box>
              </Grid2>
              <Grid2 sx={{ display: { xs: 'none', md: 'flex' } }} alignItems="center" container spacing={8}>
                {pages.map((page) => (
                  <Grid2 container spacing={4} key={page.name}>
                    <TextLink path={page.path} key={page.name} variant="h5" color={IColors.WHITE}>
                      {page.name}
                    </TextLink>
                  </Grid2>
                ))}
              </Grid2>
            </Grid2>
            <Grid2 container justifyContent="flex-end" size={{ xs: 4 }} spacing={4}>
              <Grid2>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu}>
                    <Avatar
                      alt="Jake Bayer"
                      src="https://media.licdn.com/dms/image/D4D03AQGwjQWRJ3Hctg/profile-displayphoto-shrink_400_400/0/1715755956041?e=1728518400&v=beta&t=w6zPyM6No-sedvOUmKLV10aH8gYDonf0NVstQWD5zpg"
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                      <Text sx={{ textAlign: 'center' }}>{setting}</Text>
                    </MenuItem>
                  ))}
                </Menu>
              </Grid2>
              <Grid2>
                <IconButton onClick={signOut}><ExitToApp color="action" /></IconButton>
              </Grid2>
            </Grid2>
          </Grid2>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
