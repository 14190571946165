import React from 'react';

interface IAuthenticationDataContext {
  loggedIn: boolean;
}

const authenticationContextDataDefaultValue: IAuthenticationDataContext = {
  loggedIn: false,
}

const authenticationContextDefaultValue = {
  ...authenticationContextDataDefaultValue,
  updateAuthenticationProvider: () => {},
  signOut: () => {},
}

interface IAuthenticationContext extends IAuthenticationDataContext {
  updateAuthenticationProvider: (newProviderData: Partial<IAuthenticationDataContext>) => void;
  signOut: () => void;
}

export const AuthenticationContext = React.createContext<IAuthenticationContext>(authenticationContextDefaultValue);

interface IAuthenticationContextProvider {
  children: React.ReactNode;
}

export function AuthenticationContextProvider({ children }: IAuthenticationContextProvider) {
  const [contextProviderData, setContextProviderData] = React.useState<IAuthenticationDataContext>(authenticationContextDataDefaultValue);

  const updateAuthenticationProvider = (newProviderData: Partial<IAuthenticationDataContext>) => {
    setContextProviderData( { ...contextProviderData, ...newProviderData } );
  };

  const signOut = () => {
    setContextProviderData(authenticationContextDataDefaultValue);
  };

  return (
    <AuthenticationContext.Provider value={{ ...contextProviderData, updateAuthenticationProvider, signOut }}>
      {children}
    </AuthenticationContext.Provider>
  );
}
