import React from 'react';
import { Grid2 } from '@mui/material';
import { Navigate, Route, Routes as RouterRoutes } from 'react-router-dom';
import { GetRoute, RouteConfig } from './RouteConfig';
import { Helmet } from 'react-helmet';
import { Text } from '../Shared/Text';
import { NavigationBar, NavigationBarHeight } from './NavigationBar';
import { ERoute, IRoute } from './RouteTypes';
import { RouteMaster } from './RouteMaster';
import { AuthenticationContext } from '../Contexts/Authentication';

const publicRoutes: ERoute[] = [
  ERoute.login,
  ERoute.root,
  ERoute.signup
];

export function Routes() {
  const { loggedIn } = React.useContext(AuthenticationContext);

  return (
    <RouterRoutes>
      {RouteConfig.map((route) => {
        const CurrentPathElement = (props: IRoute) => {
          const {title, path, component: children} = props;
          // Remove the leading slash to match the ERoute type
          const cleanedPath = path === '/' ? ERoute.login : path.substring(1) as ERoute;
          const pathFound = !!RouteMaster?.[cleanedPath];

          return (
            <>
              {!publicRoutes.includes(cleanedPath) && loggedIn ? (
                <>
                  <Helmet>
                    <title>{title}</title>
                  </Helmet>
                  <NavigationBar />
                  <Grid2 container sx={{ marginTop: `${NavigationBarHeight}px`, minHeight: '100vh', xs: 10 }} justifyContent="center">
                    {children}
                  </Grid2>
                </>
              ) : publicRoutes.includes(cleanedPath) ? (
                <>
                  <Helmet>
                    <title>{title}</title>
                  </Helmet>
                  {children}
                </>
              ) : !pathFound ? (
                <>
                  <Helmet>
                    <title>Page Not Found</title>
                  </Helmet>
                  <Text variant="h2">Page Not Found</Text>
                  <Navigate to={GetRoute(ERoute.login).path}/>
                </>
              ) : (
                <Navigate to={GetRoute(ERoute.login).path}/>
              )}
            </>
          );
        };

        return (
          <Route
            {...route}
            key={route.name}
            element={<CurrentPathElement {...route} />}
          />
        );
      })}
    </RouterRoutes>
  );
}
