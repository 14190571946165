import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes } from './NavigationBar/Routes';
import { primaryTheme } from './Shared/PrimaryTheme/PrimaryTheme';
import { AuthenticationContextProvider } from './Contexts/Authentication';

export function App() {

  return (
    <ThemeProvider theme={primaryTheme('light')}>
      <CssBaseline />
      <AuthenticationContextProvider>
        <Router>
          <Routes />
        </Router>
      </AuthenticationContextProvider>
    </ThemeProvider>
  );
}
