import React from 'react';

export enum ERoute {
  dashboard = 'dashboard',
  development = 'development',
  login = 'login',
  signup = 'signup',
  root = 'root',
}

export interface IRoute {
  name: ERoute;
  title: string;
  path: string;
  component: React.ReactNode;
  errorElement?: React.ReactNode;
  routes?: string;
}
